/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ meta, seoData, dateGmt, modifiedGmt, uri }) {
  const { site, wp } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            locale
            localePrefix
            siteUrl
          }
        }
        wp {
          seo {
            openGraph {
              defaultImage {
                localFile {
                  publicURL
                }
                mediaDetails {
                  width
                  height
                }
                altText
              }
            }
            social {
              twitter {
                cardType
              }
            }
          }
        }
      }
    `,
  );

  if (!seoData) return null;

  const {
    canonical,
    metaDesc,
    opengraphDescription,
    opengraphImage,
    opengraphTitle,
    opengraphType,
    title,
    twitterDescription,
    twitterTitle,
    twitterImage,
  } = seoData;

  const siteDefaultMetadata = site.siteMetadata;
  const { siteUrl, localePrefix, title: defaultTitle } = siteDefaultMetadata;
  const fallBackImage = opengraphImage || twitterImage || wp.seo.openGraph?.defaultImage;
  const siteUrlWithLocale = `${siteUrl}/${localePrefix}`;

  let finalCanonical = siteUrlWithLocale + uri;

  if (canonical && uri !== '/') {
    finalCanonical = canonical.startsWith('http') ? canonical : siteUrlWithLocale + canonical;
  }

  const articleMeta =
    opengraphType === 'article' && dateGmt
      ? [
          {
            name: 'article:published_time',
            content: dateGmt,
          },
          {
            name: 'article:modified_time',
            content: modifiedGmt,
          },
          {
            name: 'og:updated_time',
            content: modifiedGmt,
          },
        ]
      : [];

  const imageMeta = fallBackImage?.localFile
    ? [
        {
          property: 'og:image',
          content: siteUrl + fallBackImage.localFile.publicURL,
        },
        {
          property: 'og:image:width',
          content: fallBackImage.mediaDetails.width,
        },
        {
          property: 'og:image:height',
          content: fallBackImage.mediaDetails.height,
        },
        {
          name: 'twitter:image',
          content: siteUrl + (twitterImage?.localFile?.publicURL || fallBackImage.localFile.publicURL),
        },
        {
          name: 'twitter:image:width',
          content: twitterImage?.mediaDetails.width || fallBackImage.mediaDetails.width,
        },
        {
          name: 'twitter:image:height',
          content: twitterImage?.mediaDetails.height || fallBackImage.mediaDetails.height,
        },
        {
          name: 'twitter:image:alt',
          content: twitterImage?.altText || fallBackImage.altText,
        },
      ]
    : [];

  return (
    <Helmet
      title={title || defaultTitle}
      meta={[
        {
          name: 'google-site-verification',
          content: 'N41HlOqnlgd2AyjlubZKx9YpXFYT5mOF0UzaiuAiHP4',
        },
        {
          name: 'yandex-verification',
          content: 'ea0e503699f8faae',
        },
        {
          name: 'description',
          content: metaDesc,
        },
        {
          name: 'twitter:title',
          content: twitterTitle || title,
        },
        {
          name: 'twitter:description',
          content: twitterDescription || metaDesc,
        },
        {
          name: 'twitter:card',
          content: wp.seo?.social?.twitter?.cardType || 'summary_large_image',
        },
        {
          property: 'og:title',
          content: opengraphTitle || title,
        },
        {
          property: 'og:description',
          content: opengraphDescription || metaDesc,
        },
        {
          property: 'og:url',
          content: finalCanonical,
        },
        {
          property: 'og:locale',
          content: site.siteMetadata?.locale.replace('-', '_'),
        },
        {
          property: 'og:type',
          content: 'website',
        },
      ]
        .concat(imageMeta)
        .concat(articleMeta)
        .concat(meta)}
    >
      <html lang={site.siteMetadata?.locale} />
      <link rel="canonical" href={finalCanonical} />
    </Helmet>
  );
}

SEO.defaultProps = {
  meta: [],
};

SEO.propTypes = {
  meta: PropTypes.arrayOf(PropTypes.object),
  seoData: PropTypes.shape({
    canonical: PropTypes.string,
    medaDesc: PropTypes.string,
    opengraphDescription: PropTypes.string,
    opengraphImage: PropTypes.string,
    opengraphTitle: PropTypes.string,
    twitterDescription: PropTypes.string,
    twitterTitle: PropTypes.string,
    title: PropTypes.string.isRequired,
  }),
  dateGmt: PropTypes.string,
  modifiedGmt: PropTypes.string,
  uri: PropTypes.string,
};

export default SEO;
